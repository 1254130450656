@import "./../vars.scss";
.card {
    position: relative;
    border: 1px solid #2a2fab;
    box-sizing: border-box;
    border-radius: 20px;
    width: calc(50% - 10px);
    height: 400px;
    margin-bottom: 40px;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25);
    .img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background-image: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 0%,
                #2a2fab 100%
            ),
            url("./../../assets/img/card.jpg");

        background-blend-mode: multiply, normal;
        mix-blend-mode: normal;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
        mix-blend-mode: normal;
    }
    .title {
        position: absolute;
        z-index: 2;
        font-family: Product;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        bottom: 20%;
        letter-spacing: 0.01em;

        color: #ecf2ff;
    }
}
