@import "../vars.scss";

.container-process {
    width: 100%;
    height: 80vh;
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 10;
    .process {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 25%;
        height: 100%;
        transition: all 1s ease-in-out;
        &.full {
            z-index: 100;
            width: 100%;
            .process-content {
                opacity: 1;
                width: 80%;
                visibility: visible;
            }

            .inital-display {
                opacity: 0;
                transform: translateX(-100%);
            }
            .next {
                content: "Diseño";
                background: transparent;
                right: 0;
                color: #ecf2ff;
                margin-right: 40px;
            }
            .text {
                width: 100%;
                height: unset;
                margin-right: 8px;
                opacity: 1;
            }
        }
        &.empty {
            width: 0;
            visibility: hidden;
        }
        &.estrategico {
            background-color: #2a2fab;
        }
        &.diseno {
            background: #432a95;
        }
        &.marketing {
            background: #682477;
        }
        &.analisis {
            background: #77216b;
        }

        .inital-display {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: fit-content;
            font-family: Product;
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0.03em;
            transition: opacity 0.4s ease-out, transform 2s ease;

            button {
                width: 180px;
                font-family: inherit;
                font-weight: inherit;
                font-size: 16px;
                color: #77216b;
            }
            div {
                margin: 25px auto;
            }
        }

        .next {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 36px;
            width: 72px;
            height: 72px;
            border-radius: 50%;
            background-color: white;
            color: #77216b;
            top: 50%;
            right: -36px;
            z-index: 10;
            transition: all 1s ease-in-out;
        }
        .text {
            position: absolute;
            top: 50%;
            width: 0;
            height: 0;
            opacity: 0;
            font-family: $roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: right;
            color: #ecf2ff;
        }

        .process-content {
            display: flex;
            position: absolute;
            opacity: 0;
            width: 0;
            transition: all 1s ease-in-out;
            visibility: hidden;
            overflow-y: hidden;
            overflow-x: hidden;
            .column-1 {
                display: flex;
                width: 50%;
                .description-col {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
            .column-2 {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 50%;
                button {
                    width: 180px;
                }
                .list {
                    width: 100%;
                    ul {
                        padding: 0;
                        li {
                            margin: 7px auto;
                            list-style: none;
                        }
                    }
                    h3 {
                        display: inline-block;
                        margin-bottom: 0;
                    }
                    .list-cols {
                        display: flex;
                        .list-col-1 {
                            width: 50%;
                        }
                        .list-col-2 {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}
