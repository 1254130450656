@import "../vars.scss";

.container-us {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("./../../assets/img/usBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    z-index: 200;
    transition: all 1s ease-in-out;
    [data-theme="dark"] & {
        background-image: url("./../../assets/img/usBgDark.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }
    .title-us {
        font-family: Product;
        font-size: 24px;
        letter-spacing: 0.03em;
        color: #2a2fa9;
        margin: 59px auto;
        [data-theme="dark"] & {
            color: #fff;
        }
    }
    .container-objectives {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: 30px 0;
        .object {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: center;
            padding: 10px;
            width: 20%;
            color: #263238;
            font-family: $roboto;
            font-size: 20px;
            transition: all 1s ease-in-out;
            [data-theme="dark"] & {
                color: #fff;
            }
            div {
                background-repeat: no-repeat;
                background-position: center;
                min-height: 126px;
                transition: inherit;
                &.xpLogo {
                    background-image: url("./../../assets/img/xpLogo.svg");
                    [data-theme="dark"] & {
                        background-image: url("./../../assets/img/xpLogoDark.svg");
                    }
                }

                &.effectiveLogo {
                    background-image: url("./../../assets/img/efectiveLogo.svg");
                    [data-theme="dark"] & {
                        background-image: url("./../../assets/img/efectiveLogoDark.svg");
                    }
                }

                &.committedLogo {
                    background-image: url("./../../assets/img/committedLogo.svg");
                    [data-theme="dark"] & {
                        background-image: url("./../../assets/img/committedLogoDark.svg");
                    }
                }
            }
            h3 {
                font-family: Product;
                color: #77216b;
                font-size: 24px;
                transition: inherit;
                [data-theme="dark"] & {
                    color: #fff;
                }
            }
        }
    }
}
